import React from 'react';
import mapimage from '../../assets/images/mapimage.png';

export default function Map() {

 

  return (
<>   

<div className='mt-20' id="locations">

<img  src={mapimage}/>
</div>


</> 
  )
}
